<template>
  <service-layout type="quotation" :data="form.data" :init="form.init" @confirm="detail.confirm">
    <template #operation>
      <button-ajax v-if="form.data.status?.code === 'F'" type="primary" :method="operation.save.click"> 保存 </button-ajax>
      <en-dropdown v-if="form.data.status?.code === 'F'" @command="operation.option.command">
        <en-button>操作</en-button>
        <template #dropdown>
          <en-dropdown-item command="detail">估价信息</en-dropdown-item>
          <en-dropdown-item command="customer">客户档案</en-dropdown-item>
          <en-dropdown-item command="vehicle">车辆档案</en-dropdown-item>
          <en-dropdown-item command="vehicle">单据档案</en-dropdown-item>
          <en-dropdown-item command="delete">删除估价</en-dropdown-item>
          <en-dropdown-item command="logs">单据历史</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <template #manifest>
      <table-manifest
        code="SVSQTFTFLD"
        :routes="[
          { path: '/service/business/workorder', name: '工单' },
          { path: '/service/business/quotation', name: '估价单' },
          { path: '/service/business/inspection', name: '检测单' }
        ]"
        :ajax="{ action: 'GET /enocloud/service/quotation/query' }"
        :props="{ start: 'preparedStartDate', end: 'preparedEndDate' }"
        @expand-click="dialog.visible = true"
        @row-click="manifest.row.click"
        :ref="setRef('mainfest')"
      >
        <template #VEHICLE_SPEC="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationQueryDto'] }">
          {{ row.vehicleSpec?.split('^').join(',') }}
        </template>

        <template #STATUS="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationQueryDto'] }">
          <div class="flex gap-2 cursor-pointer">
            <en-tag>{{ row.status?.message }}</en-tag>
            <en-tag size="small" :type="row.vehicleServiceGroup?.serviceId ? 'success' : 'info'" @click.stop="manifest.workorder.click(row)">
              工
            </en-tag>
            <en-tag size="small">估</en-tag>
            <en-tag
              size="small"
              :type="row.vehicleServiceGroup?.vehicleInspectionId ? 'success' : 'info'"
              @click.stop="manifest.inspection.click(row)"
            >
              检
            </en-tag>
          </div>
        </template>

        <template #form="{ data }">
          <en-form-item label="单号">
            <en-input v-model="data.serialNo"></en-input>
          </en-form-item>
          <en-form-item label="车主">
            <en-input v-model="data.customerName"></en-input>
          </en-form-item>
          <en-form-item label="手机号">
            <en-input v-model="data.cellphone"></en-input>
          </en-form-item>
          <en-form-item label="车架号">
            <en-input v-model="data.vin"></en-input>
          </en-form-item>
          <en-form-item label="服务顾问">
            <select-maintain
              v-model="data.advisorName"
              :ajax="{
                action: 'GET /enocloud/common/user',
                params: (params, name) => (params.payload = { name, includingDestroyed: true, advisorFirst: true })
              }"
              :props="{ label: 'name', value: 'name' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="维修项目">
            <select-maintain
              v-model="data.maintenanceId"
              :ajax="{ action: 'GET /enocloud/common/maintenance', params: (params, name) => (params.payload = { name }) }"
              :props="{ label: 'name', value: 'id' }"
              class="w-full"
              remote
              multiple
            ></select-maintain>
          </en-form-item>
          <en-form-item label="用料编号">
            <en-input v-model="data.goodsSerialNo"></en-input>
          </en-form-item>
          <en-form-item label="客户来源">
            <select-maintain
              v-model="data.salesmanType"
              :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['CLKTYPE']) }"
              :props="{ label: 'message', value: 'code' }"
              @change="
                () => {
                  data.salesmanName = ''
                  refs.formSalesmanNameRef?.ajax()
                }
              "
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item v-if="data.salesmanType && data.salesmanType !== 'N'" label="介绍人">
            <template v-if="['C', 'U'].includes(data.salesmanType)">
              <select-maintain
                v-model="data.salesmanName"
                :ref="setRef('formSalesmanNameRef')"
                :ajax="{
                  action: 'GET /enocloud/common/clerk',
                  params: (params, value) => (params.payload = { code: data.salesmanType, quickSearch: value })
                }"
                :props="{ label: 'name', value: '' }"
                value-key="id"
                remote
                class="w-full"
              ></select-maintain>
            </template>
            <template v-if="['O', 'I'].includes(data.salesmanType)">
              <select-maintain
                v-model="data.salesmanName"
                :ajax="{
                  action: 'GET /enocloud/common/hint/:hintTypeStr',
                  params: (params) => (params.paths = [data.salesmanType === 'O' ? 'SALSMAN' : 'CUSISNSSMAN'])
                }"
                :props="{ label: 'name', value: 'name' }"
                allow-create
                class="w-full"
              ></select-maintain>
            </template>
          </en-form-item>
          <en-form-item label="创建时间">
            <en-date-picker v-model:start="data.preparedStartDate" v-model:end="data.preparedEndDate" type="daterange"></en-date-picker>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <en-card v-loading="form.loading" body-class="flex flex-col gap-6">
      <div class="flex gap-4">
        <span>业务选择：估价</span>
        <span>维修类别：{{ form.data.serviceCategory?.name }}</span>
      </div>

      <en-collapse>
        <en-collapse-item>
          <template #title>
            <div class="flex items-center gap-6">
              <span>{{ form.data.vehicle?.plateNo }}</span>
              <span>{{ form.data.customer?.name }}/{{ form.data.customer?.cellphone }}</span>
              <span>单号：{{ form.data.serialNo }}</span>
              <en-tag>{{ form.data.status?.message }}</en-tag>
              <span>{{ form.data.vehicle?.vehicleSpec.join('/') }}</span>
              <span>{{ form.data.vehicle?.vin }}</span>
            </div>
          </template>
          <div class="grid grid-cols-4 gap-x-6">
            <span>服务顾问： {{ form.data.advisor?.name }}</span>
            <span>维修类别 {{ form.data.serviceCategory?.name }}</span>
            <span>本次里程： {{ form.data.currentMileage }}</span>
            <span>客户来源： {{ form.data.salesmanType?.message }}</span>
            <span>介绍人： {{ form.data.salesman?.name }}</span>
            <span>发票类型： {{ form.data.invoiceTypeName }}</span>
            <span>预计完工： {{ formatDate(form.data.expectedCompletionDatetime, true) }}</span>
            <span>是否保养： {{ form.data.maintenanceFlag?.message }}</span>
            <span>下保里程： {{ form.data.nextMaintenanceMileage }}</span>
            <span>下保时间： {{ formatDate(form.data.nextMaintenanceDate) }}</span>
            <span>回收旧件： {{ form.data.recycleFlag?.message }}</span>
            <span>本次存油： {{ form.data.remainingOil?.message }}</span>
            <span>备注： {{ form.data.comment }}</span>
            <span>故障描述： {{ form.data.description?.join(',') }}</span>
            <span>故障原因： {{ form.data.solution }}</span>
          </div>
        </en-collapse-item>
      </en-collapse>
    </en-card>

    <en-card v-loading="form.loading" class="flex-1 overflow-auto" body-class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-tabs v-model="tabs.active">
            <en-tab-pane
              label="项目"
              name="maintenance"
              :badge="form.data.maintenances?.length"
              :style="{ height: height - 55 + 'px', overflow: 'auto' }"
              class="gap-6"
            >
              <div class="flex gap-4">
                <select-maintain
                  :ajax="{
                    action: 'GET /enocloud/common/maintenance',
                    params: (params, value) => (params.payload = { quickSearch: value, ignoreSpraySurface: true })
                  }"
                  :props="{
                    label: 'name',
                    value: '',
                    disabled: (option: EnocloudCommonDefinitions['MaintenanceDto']) =>
                      Boolean(form.data.maintenances?.find((item) => item.maintenance?.id === option.id))
                  }"
                  value-key="id"
                  placeholder="请选择维修项目"
                  remote
                  allow-create
                  :disabled="form.disabled"
                  class="w-110"
                  @change="form.maintenances.operation.change"
                ></select-maintain>
                <en-button type="primary" :disabled="form.disabled" @click="form.maintenances.operation.selection.click">批量添加项目</en-button>
                <en-button
                  type="primary"
                  :disabled="
                    form.disabled ||
                    !form.data.vehicleServiceGroup?.serviceId ||
                    service.loading ||
                    service.data.status?.code === 'AA' ||
                    service.data.status?.code === 'PD' ||
                    service.data.status?.code === 'DC'
                  "
                  @click="form.maintenances.operation.batchToService.click"
                >
                  批量添加到工单
                </en-button>
                <en-button type="primary" :disabled="form.disabled" @click="form.maintenances.operation.spray.click">喷漆</en-button>
              </div>

              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    :ref="setRef('formMaintenanceTable')"
                    :height="height"
                    code="SQTMTFLD"
                    :data="form.data.maintenances"
                    :show-selectable="!form.disabled"
                    v-model:selection="form.maintenances.selection.data"
                  >
                    <template #OPERATION="{ row, $index }: { row: EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto']; $index: number }">
                      <en-button
                        type="primary"
                        link
                        :disabled="form.disabled || Boolean(service.data.maintenances?.find((item) => item.maintenance?.id === row.maintenance?.id))"
                        @click="form.maintenances.delete.click($index)"
                      >
                        删除
                      </en-button>
                      <button-ajax
                        link
                        :disabled="
                          form.disabled ||
                          !form.data.vehicleServiceGroup?.serviceId ||
                          !row.id ||
                          service.loading ||
                          Boolean(service.data.maintenances?.find((item) => item.maintenance?.id === row.maintenance?.id)) ||
                          service.data.status?.code === 'AA' ||
                          service.data.status?.code === 'PD' ||
                          service.data.status?.code === 'DC'
                        "
                        :method="form.maintenances.toService.click"
                        :params="row"
                      >
                        {{ service.data.maintenances?.find((item) => item.maintenance?.id === row.maintenance?.id) ? '已添加' : '添加到工单' }}
                      </button-ajax>
                    </template>

                    <template #MAINTENANCE="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto'] }">
                      <template v-if="row.maintenance">
                        <select-maintain
                          v-model="row.maintenance"
                          :ajax="{
                            action: 'GET /enocloud/common/maintenance',
                            params: (params, value) => (params.payload = { name: value, ignoreSpraySurface: true })
                          }"
                          :props="{
                            label: 'name',
                            value: '',
                            disabled: (option: EnocloudCommonDefinitions['MaintenanceDto']) =>
                              Boolean(form.data.maintenances?.find((item) => item.maintenance?.id === option.id)) && option.id !== row.maintenance?.id
                          }"
                          value-key="id"
                          allow-create
                          remote
                          :disabled="form.disabled"
                          @change="form.maintenances.serialNo.change(row, $event as EnocloudCommonDefinitions['MaintenanceDto'])"
                          class="w-full"
                        ></select-maintain>
                      </template>
                      <template v-else>
                        <en-input v-model="row.name"></en-input>
                      </template>
                    </template>

                    <template #SERIAL_NO="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto'] }">
                      {{ row.maintenance?.serialNo }}
                    </template>

                    <template #LABOR_HOUR="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto'] }">
                      <en-input-number
                        v-model="row.laborHour"
                        :min="0"
                        :disabled="form.disabled"
                        @change="form.maintenances.laborHour.change(row)"
                        class="w-full"
                      ></en-input-number>
                    </template>

                    <template #PRICE="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto'] }">
                      <en-input-number
                        v-model="row.price"
                        :min="0"
                        :disabled="form.disabled"
                        @change="form.maintenances.price.change(row)"
                        class="w-full"
                      ></en-input-number>
                    </template>

                    <template #DISCOUNT="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto'] }">
                      <en-input-rate
                        v-model="row.discountRate"
                        :min="0"
                        :disabled="form.disabled"
                        @change="form.maintenances.discountRate.change(row)"
                        class="w-full"
                      ></en-input-rate>
                    </template>

                    <template #AMOUNT="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto'] }">
                      {{ formatMoney(calculator.mul(row.laborHour, row.price)) }}
                    </template>

                    <template #AMOUNT_AFTER_DISCOUNT="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto'] }">
                      {{ formatMoney(calculator.mul(calculator.mul(row.laborHour, row.price), row.discountRate)) }}
                    </template>

                    <template #CHARGING_METHOD="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto'] }">
                      <select-maintain
                        v-model="row.chargingMethod"
                        :ajax="{
                          action: 'GET /enocloud/common/lookup/:lookupType',
                          params: (params) => {
                            params.paths = ['CHGMTD']
                            params.payload = { excludes: ['RWK'] }
                          }
                        }"
                        :props="{
                          label: 'message',
                          value: '',
                          disabled: (option: EnocloudCommonDefinitions['LookupDto']) => !option.forServiceMaintenance
                        }"
                        :disabled="form.disabled"
                        value-key="code"
                      ></select-maintain>
                    </template>

                    <template #COMMENT="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto'] }">
                      <en-input v-model="row.comment" :disabled="form.disabled"></en-input>
                    </template>

                    <template #PRINT="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto'] }">
                      <en-checkbox
                        :model-value="row.print?.value"
                        :disabled="form.disabled"
                        @change="form.maintenances.print.change(row, $event as boolean)"
                      ></en-checkbox>
                    </template>
                  </table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>

            <en-tab-pane
              label="配件"
              name="goods"
              :badge="form.data.planGoods?.length"
              :style="{ height: height - 55 + 'px', overflow: 'auto' }"
              class="gap-6"
            >
              <div class="flex gap-4">
                <select-maintain
                  :ajax="{
                    action: 'GET /enocloud/common/goods',
                    params: (params, value) =>
                      (params.payload = {
                        quickSearch: value,
                        statusCode: 'A',
                        rsexp: 'id,name,specifications[id,name,goodsSpecificationAddition[*]]'
                      })
                  }"
                  :props="{
                    label: 'name',
                    value: '',
                    disabled: (option: EnocloudCommonDefinitions['MaintenanceDto']) =>
                      Boolean(form.data.planGoods?.find((item) => item.goodsSpecification?.goods?.id === option.id))
                  }"
                  value-key="id"
                  :disabled="form.disabled"
                  placeholder="请选择配件"
                  remote
                  class="w-110"
                  @change="form.planGoods.operation.change"
                ></select-maintain>
                <en-button type="primary" @click="form.planGoods.operation.selection.click">批量添加配件</en-button>
                <en-button
                  type="primary"
                  :disabled="
                    !form.data.vehicleServiceGroup?.serviceId ||
                    service.loading ||
                    service.data.status?.code === 'AA' ||
                    service.data.status?.code === 'PD' ||
                    service.data.status?.code === 'DC'
                  "
                  @click="form.planGoods.operation.batchToService.click"
                >
                  批量添加到工单
                </en-button>

                <en-button type="primary" :disabled="form.disabled" @click="form.planGoods.operation.add.click">自定义配件</en-button>
              </div>

              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="SQTGDFLD"
                    :data="form.data.planGoods"
                    :height="height"
                    :loading="form.loading"
                    :ref="setRef('formPlanGoodsTable')"
                  >
                    <template #OPERATION="{ row, $index }: { row: EnocloudServiceDefinitions['ServiceQuotationGoodsDto']; $index: number }">
                      <en-button type="primary" link :disabled="form.disabled" @click="form.planGoods.delete.click($index)">删除</en-button>
                      <button-ajax
                        link
                        :disabled="
                          form.disabled ||
                          !form.data.vehicleServiceGroup?.serviceId ||
                          !row.id ||
                          service.loading ||
                          Boolean(service.data.goods?.find((item) => item.goods?.id === row.goods?.id)) ||
                          service.data.status?.code === 'AA' ||
                          service.data.status?.code === 'PD' ||
                          service.data.status?.code === 'DC'
                        "
                        :method="form.planGoods.toService.click"
                        :params="row"
                      >
                        {{ service.data.goods?.find((item) => item.goods?.id === row.goods?.id) ? '已添加' : '添加到工单' }}
                      </button-ajax>
                    </template>

                    <template #SERIAL_NO="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationGoodsDto'] }">
                      {{ row.goodsSpecification?.goods?.serialNo }}
                    </template>

                    <template #NAME="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationGoodsDto'] }">
                      <template v-if="row.goods">
                        <select-maintain
                          v-model="row.goods"
                          :ajax="{
                            action: 'GET /enocloud/common/goods',
                            params: (params, name) =>
                              (params.payload = { name, statusCode: 'A', rsexp: 'id,name,specifications[id,name,goodsSpecificationAddition[*]]' })
                          }"
                          :props="{ label: 'name', value: '' }"
                          value-key="id"
                          remote
                          :disabled="form.disabled"
                          @change="form.planGoods.goods.change(row, $event as EnocloudServiceDefinitions['GoodsDto'])"
                          class="w-full"
                        ></select-maintain>
                      </template>
                      <template v-else>
                        <en-input v-model="row.name"></en-input>
                      </template>
                    </template>

                    <template #GOODS_TYPE="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationGoodsDto'] }">
                      <select-maintain
                        v-model="row.type"
                        :ajax="{
                          action: 'GET /enocloud/common/lookup/:lookupType',
                          params: (params) => (params.paths = ['GOODSTP'])
                        }"
                        :props="{ label: 'message', value: '' }"
                        value-key="code"
                        :disabled="form.disabled"
                        class="w-full"
                      ></select-maintain>
                    </template>

                    <template #COUNT="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationGoodsDto'] }">
                      <en-input-number
                        v-model="row.count"
                        :min="0"
                        :disabled="form.disabled"
                        @change="form.planGoods.count.change(row)"
                        class="w-full"
                      ></en-input-number>
                    </template>

                    <template #PRICE="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationGoodsDto'] }">
                      <en-input-number
                        v-model="row.price"
                        :min="0"
                        :precision="2"
                        :disabled="form.disabled"
                        @change="form.planGoods.price.change(row)"
                        class="w-full"
                      ></en-input-number>
                    </template>

                    <template #PRICE2="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationGoodsDto'] }">
                      <en-input-number v-model="row.price2" :min="0" :precision="2" :disabled="form.disabled" class="w-full"></en-input-number>
                    </template>

                    <template #PRICE3="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationGoodsDto'] }">
                      <en-input-number v-model="row.price3" :min="0" :precision="2" :disabled="form.disabled" class="w-full"></en-input-number>
                    </template>

                    <template #DISCOUNT_RATE="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationGoodsDto'] }">
                      <en-input-rate
                        v-model="row.discountRate"
                        :min="0"
                        @change="form.planGoods.discountRate.change(row)"
                        :disabled="form.disabled"
                        class="w-full"
                      ></en-input-rate>
                    </template>

                    <template #AMOUNT="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationGoodsDto'] }">
                      {{ formatMoney(calculator.mul(calculator.mul(row.count, row.price), row.discountRate)) }}
                    </template>

                    <template #UNIT="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationGoodsDto'] }">
                      <select-maintain
                        v-if="row.goodsSpecification?.id"
                        v-model="row.unit"
                        :options="row.goodsSpecification?.goods?.specifications"
                        :props="{ label: 'name', value: 'name' }"
                        :disabled="form.disabled"
                        class="w-full"
                      ></select-maintain>
                      <en-input v-else v-model="row.unit"></en-input>
                    </template>

                    <template #CHARGING_METHOD="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationGoodsDto'] }">
                      <select-maintain
                        v-model="row.chargingMethod"
                        :ajax="{
                          action: 'GET /enocloud/common/lookup/:lookupType',
                          params: (params) => {
                            params.paths = ['CHGMTD']
                            params.payload = { excludes: ['RWK'] }
                          }
                        }"
                        :props="{
                          label: 'message',
                          value: '',
                          disabled: (option: EnocloudCommonDefinitions['LookupDto']) => !option.forServiceMaintenance
                        }"
                        value-key="code"
                        :disabled="form.disabled"
                      ></select-maintain>
                    </template>

                    <template #GOODS_COMMENT="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationGoodsDto'] }">
                      <en-input
                        :model-value="row.goods?.comment"
                        :disabled="form.disabled"
                        @change="form.planGoods.goodsComment.change(row)"
                      ></en-input>
                    </template>

                    <template #COMMENT="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationGoodsDto'] }">
                      <en-input v-model="row.comment" :disabled="form.disabled"></en-input>
                    </template>

                    <template #PRINT="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationGoodsDto'] }">
                      <en-checkbox
                        :model-value="row.print?.value"
                        :disabled="form.disabled"
                        @change="form.planGoods.print.change(row, $event as boolean)"
                      ></en-checkbox>
                    </template>
                  </table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>

            <en-tab-pane label="历史估价项目" name="maintenance-history">
              <en-table
                :data="form.maintenanceHistory.data"
                :loading="form.maintenanceHistory.loading"
                :height="height - 55"
                pagination
                :paging="form.maintenanceHistory.paging"
              >
                <en-table-column label="单据日期" prop="serviceQuotation.preparedDatetime">
                  <template #default="{ row }: { row: any }">{{ formatDate(row.serviceQuotation.preparedDatetime) }}</template>
                </en-table-column>
                <en-table-column label="估价单号" prop="serviceQuotation.serialNo"></en-table-column>
                <en-table-column label="服务顾问" prop="serviceQuotation.advisor.name"></en-table-column>
                <en-table-column label="项目编号" prop="maintenance.serialNo"></en-table-column>
                <en-table-column label="维修项目" prop="name"></en-table-column>
                <en-table-column label="工时" prop="laborHour"></en-table-column>
                <en-table-column label="单价" prop="price"></en-table-column>
                <en-table-column label="原金额">
                  <template #default="{ row }: { row: any }">{{ formatMoney(calculator.mul(row.laborHour, row.price)) }}</template>
                </en-table-column>
                <en-table-column label="折扣" prop="discountRate"></en-table-column>
                <en-table-column label="金额">
                  <template #default="{ row }: { row: any }">
                    {{ formatMoney(calculator.mul(calculator.mul(row.laborHour, row.price), row.discountRate)) }}
                  </template>
                </en-table-column>
                <en-table-column label="收费类别" prop="chargingMethod.message"></en-table-column>
              </en-table>
            </en-tab-pane>

            <en-tab-pane label="历史估价配件" name="planGoods-history">
              <en-table
                :data="form.planGoodsHistory.data"
                :loading="form.planGoodsHistory.loading"
                :height="height - 55"
                pagination
                :paging="form.planGoodsHistory.paging"
                >>
                <en-table-column label="单据日期" prop="serviceQuotation.preparedDatetime">
                  <template #default="{ row }: { row: any }">{{ formatDate(row.serviceQuotation.preparedDatetime) }}</template>
                </en-table-column>
                <en-table-column label="估价单号" prop="serviceQuotation.serialNo"></en-table-column>
                <en-table-column label="服务顾问" prop="serviceQuotation.advisor.name"></en-table-column>
                <en-table-column label="编码" prop="serialNo"></en-table-column>
                <en-table-column label="名称" prop="name"></en-table-column>
                <en-table-column label="用料备注" prop="comment"></en-table-column>
                <en-table-column label="品质要求" prop="type.message"></en-table-column>
                <en-table-column label="数量" prop="count"></en-table-column>
                <en-table-column label="单价" prop="price"></en-table-column>
                <en-table-column label="金额">
                  <template #default="{ row }: { row: any }">{{ formatMoney(calculator.mul(row.price, row.count)) }}</template>
                </en-table-column>
                <en-table-column label="单位" prop="unit"></en-table-column>
                <en-table-column label="收费类别" prop="chargingMethod.message"></en-table-column>
                <en-table-column label="单价二" prop="price2"></en-table-column>
                <en-table-column label="单价三" prop="price3"></en-table-column>
                <en-table-column label="备注" prop="serviceQuotation.comment"></en-table-column>
              </en-table>
            </en-tab-pane>
          </en-tabs>
        </template>
      </flex-box>
    </en-card>
  </service-layout>

  <quotation-dialog v-model="dialog.visible"></quotation-dialog>

  <quotation-detail v-model="detail.visible" :data="form.data" @confirm="detail.confirm"></quotation-detail>

  <customer-record
    v-model="customerRecord.visible"
    :customer-data="form.data.customer"
    :vehicle-data="form.data.vehicle"
    @confirm="form.get"
  ></customer-record>

  <vehicle-record v-model="vehicleRecord.visible" :data="form.data.vehicle" @confirm="form.get"></vehicle-record>

  <quotation-logs v-model="logs.visible" :data="form.data"></quotation-logs>

  <maintenance-selection v-model="maintenanceSelection.visible" ignore-spray-surface @confirm="maintenanceSelection.confirm"></maintenance-selection>

  <goods-selection v-model="goodsSelection.visible" type="quotation" @confirm="goodsSelection.confirm"></goods-selection>

  <en-dialog v-model="spraySurface.visible" title="喷涂钣面" center width="1600">
    <spray-surface-selection v-model="spraySurface.data.codes" @change="spraySurface.codes.change"></spray-surface-selection>

    <div class="mt-4">
      <en-table :data="spraySurface.data.maintenances">
        <en-table-column type="index" label="序号" width="60"></en-table-column>
        <en-table-column label="项目名称" prop="maintenance.name">
          <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto'] }">
            <select-maintain
              v-if="!row.maintenance?.id || row.maintenance.spraySurface?.startsWith('OTH')"
              v-model="row.maintenance"
              :ajax="{ action: 'GET /enocloud/common/maintenance', params: (params) => (params.payload = { spraySurface: 'OTH' }) }"
              :props="{ label: 'name', value: '' }"
              value-key="id"
              class="w-full"
              @change="spraySurface.maintenances.change(row)"
            ></select-maintain>
            <span v-else>{{ row.maintenance?.name }}</span>
          </template>
        </en-table-column>
        <en-table-column label="面积">
          <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto'] }">
            {{ row.maintenance?.square ? `${row.maintenance.square}m²` : '' }}
          </template>
        </en-table-column>
        <en-table-column label="备注" prop="">
          <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto'] }">
            <en-input v-model="row.comment"></en-input>
          </template>
        </en-table-column>
        <en-table-column label="工时" prop="">
          <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto'] }">
            <en-input-number
              v-model="row.laborHour"
              :disabled="store.attribute.FCMTNCS.value === 'Y' || store.attribute.SVMTVLM.value === 'P'"
              class="w-full"
            ></en-input-number>
          </template>
        </en-table-column>
        <en-table-column label="单价" prop="">
          <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto'] }">
            <en-input-number v-model="row.price" :precision="2" :disabled="store.attribute.FCMTNCS.value === 'Y'" class="w-full"></en-input-number>
          </template>
        </en-table-column>
        <en-table-column label="操作" prop="">
          <template #default="{ $index }: { $index: number }">
            <en-button link type="primary" @click="spraySurface.maintenances.delete.click($index)">删除</en-button>
          </template>
        </en-table-column>
      </en-table>

      <en-button text type="primary" @click="spraySurface.maintenances.add.click">特殊喷漆</en-button>
    </div>

    <template #footer>
      <en-button @click="spraySurface.footer.cancel.click">取消</en-button>
      <en-button type="primary" @click="spraySurface.footer.confirm.click">确定</en-button>
    </template>
  </en-dialog>
</template>

<script lang="ts">
import { cloneDeep, find, difference, findIndex, isNil, map } from 'lodash-es'
import {
  CustomerRecord,
  VehicleRecord,
  MaintenanceSelection,
  GoodsSelection,
  SpraySurfaceSelection,
  type MaintenanceSelectionOption,
  type GoodsSelectionOption
} from '@enocloud/business'

import ServiceLayout from '@service/components/service-layout.vue'

import QuotationDetail from '@service/components/quotation-detail.vue'
import QuotationDialog from '@service/components/quotation-dialog.vue'
import QuotationLogs from '@service/components/quotation-logs.vue'
import { EnMessage, EnMessageBox } from '@enocloud/components'
import { calculator } from '@enocloud/utils'

const formMaintenanceInit = <T extends EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto']>(props: Partial<T> = {}): T => {
  const data = {
    discountRate: 1,
    laborHour: 1,
    price: 0,
    print: { code: 'Y' },
    chargingMethod: { code: 'CLT', message: '自费维修', description: '', type: '' }
  } as T
  return Object.assign(data, props)
}

const formGoodsInit = <T extends EnocloudServiceDefinitions['ServiceQuotationGoodsDto']>(props: Partial<T> = {}): T => {
  const data = {
    discountRate: 1,
    count: 0,
    price: 0,
    chargingMethod: { code: 'CLT', message: '自费维修', description: '', type: '' },
    print: { code: 'Y' },
    type: { code: 'N' }
  } as T
  return Object.assign(data, props)
}

export default factory({
  page: true,

  components: {
    ServiceLayout,
    QuotationDetail,
    QuotationDialog,
    CustomerRecord,
    VehicleRecord,
    QuotationLogs,
    MaintenanceSelection,
    GoodsSelection,
    SpraySurfaceSelection
  },

  config: {
    children: {
      title: {
        value: '估价单'
      },
      operation: {
        save: {
          async click() {
            await this.form.submit()
            return this.form.get()
          }
        },
        complete: {
          async click() {
            await this.dirtyCheck('form')
            await this.form.submit()
            await this.form.complete()
            return this.form.get()
          }
        },
        option: {
          async command(option: string) {
            await this.dirtyCheck('form')
            switch (option) {
              case 'detail':
                this.detail.visible = true
                break
              case 'customer':
                this.customerRecord.visible = true
                break
              case 'vehicle':
                this.vehicleRecord.visible = true
                break
              case 'delete':
                const res = await EnMessageBox.prompt('备注', '提示', {
                  confirmButtonText: '确定',
                  inputValidator: (value) => {
                    return value !== null && value !== ''
                  },
                  inputErrorMessage: '请填写备注'
                })
                if (res.action === 'confirm') {
                  await this.form.discard({ addition: { comment: res.value } })
                  this.form.get()
                  this.refs.mainfest.table.refresh()
                }
                break
              case 'logs':
                this.logs.visible = true
                break
            }
          }
        }
      },
      manifest: {
        row: {
          click(row: EnocloudServiceDefinitions['ServiceQuotationQueryDto']) {
            this.form.init()
            this.form.data.id = row.id
            this.form.get()
          }
        },
        workorder: {
          click(row: EnocloudServiceDefinitions['ServiceQuotationDto']) {
            if (!row.vehicleServiceGroup?.serviceId) return
            this.router.push('/service/business/workorder', (vm) => {
              vm.form.data.id = row.vehicleServiceGroup?.serviceId
              vm.form.get()
            })
          }
        },
        inspection: {
          click(row: EnocloudServiceDefinitions['ServiceQuotationDto']) {
            if (!row.vehicleServiceGroup?.vehicleInspectionId) return
            this.router.push('/service/business/inspection', (vm) => {
              vm.form.data.id = row.vehicleServiceGroup?.vehicleInspectionId
              vm.form.get()
            })
          }
        }
      },
      tabs: {
        active: 'maintenance'
      },
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/quotation/:serviceQuotationId',
            data: 'object',
            init: true,
            loading: true,
            dirty: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'PUT /enocloud/service/quotation',
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          complete: {
            action: 'PUT /enocloud/common/vehicle/inspection/report/:reportId/complete',
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          discard: {
            action: 'POST /enocloud/service/quotation/:serviceQuotationId/discard',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          }
        },
        computed: {
          disabled() {
            return this.form.data.status?.code === 'D'
          }
        },
        children: {
          maintenances: {
            operation: {
              change(value: EnocloudCommonDefinitions['MaintenanceDto'] | string) {
                const init = formMaintenanceInit()
                if (typeof value === 'string') {
                  init.name = value
                } else {
                  init.maintenance = value
                }
                this.form.data.maintenances.push(init)
              },
              selection: {
                click() {
                  this.maintenanceSelection.visible = true
                }
              },
              batchToService: {
                async click() {
                  if (!this.form.maintenances.selection.data.length) return EnMessage.warning('请先选择项目')
                  const data = cloneDeep(this.service.data)
                  for (const item of this.form.maintenances.selection.data) {
                    data.maintenances?.push({
                      name: item.name || item.maintenance?.name,
                      maintenance: item.maintenance,
                      chargingMethod: item.chargingMethod,
                      discountRate: item.discountRate,
                      laborHour: item.laborHour,
                      price: item.price,
                      amountBeforeDiscount: 0,
                      assignees: [],
                      count: 0,
                      internalLaborHour: 0,
                      maintenanceGoods: [],
                      reworks: [],
                      inflatedFlag: { code: 'N', message: '', type: '', description: '' },
                      comment: item.comment
                    })
                  }
                  await this.service.submit({ addition: data })
                  this.service.get()
                  this.refs.formMaintenanceTable.clearSelection()
                  return EnMessage.success('添加成功')
                }
              },
              spray: {
                click() {
                  this.spraySurface.data.maintenances = this.form.data.maintenances?.filter((item) => !!item.maintenance?.spraySurface) ?? []
                  this.spraySurface.data.codes = this.spraySurface.data.maintenances.map((item) => item.maintenance!.spraySurface!) ?? []
                  this.spraySurface.visible = true
                }
              }
            },
            selection: {
              data: [] as EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto'][]
            },
            delete: {
              click(index: number) {
                this.form.data.maintenances.splice(index, 1)
              }
            },
            toService: {
              async click(row: EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto']) {
                const data = cloneDeep(this.service.data)
                data.maintenances?.push({
                  name: row.name || row.maintenance?.name,
                  maintenance: row.maintenance,
                  chargingMethod: row.chargingMethod,
                  discountRate: row.discountRate,
                  laborHour: row.laborHour,
                  price: row.price,
                  amountBeforeDiscount: 0,
                  assignees: [],
                  count: 0,
                  internalLaborHour: 0,
                  maintenanceGoods: [],
                  reworks: [],
                  inflatedFlag: { code: 'N', message: '', type: '', description: '' },
                  comment: row.comment
                })
                await this.service.submit({ addition: data })
                this.service.get()
                return EnMessage.success('添加成功')
              }
            },
            serialNo: {
              change(row: EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto'], value: EnocloudCommonDefinitions['MaintenanceDto']) {}
            },
            price: {
              change(row: EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto']) {}
            },
            laborHour: {
              change(row: EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto']) {}
            },
            discountRate: {
              change(row: EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto']) {}
            },
            print: {
              change(row: EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto'], value: boolean) {
                row.print = { code: value ? 'Y' : 'N', message: '', type: '', description: '', value }
              }
            }
          },
          planGoods: {
            operation: {
              change(goods: EnocloudServiceDefinitions['GoodsDto']) {
                const { specifications } = goods
                const goodsSpecification = find(specifications, ['defaultService.value', true]) || specifications?.[0]
                goodsSpecification!.goods = cloneDeep(goods)
                this.form.data.planGoods.push(formGoodsInit({ goods, goodsSpecification, price: goodsSpecification?.servicePrice }))
              },
              selection: {
                click() {
                  this.goodsSelection.visible = true
                }
              },
              batchToService: {
                async click() {
                  if (!this.form.planGoods.selection.data.length) return EnMessage.warning('请先选择配件')
                  const data = cloneDeep(this.service.data)
                  for (const item of this.form.planGoods.selection.data) {
                    data.goods?.push({
                      goods: item.goods,
                      goodsSpecification: item.goodsSpecification,
                      amount: 0,
                      amountBeforeDiscount: 0,
                      count: 0,
                      discountRate: item.discountRate,
                      fakeCount: 0,
                      histories: [],
                      materialCount: 0,
                      materialFakeCount: 0,
                      planCount: item.count,
                      price: item.price,
                      returnedCount: 0,
                      comment: item.comment,
                      chargingMethod: item.chargingMethod
                    })
                  }
                  await this.service.submit({ addition: data })
                  this.service.get()
                  this.refs.formPlanGoodsTable.clearSelection()
                  return EnMessage.success('添加成功')
                }
              },
              add: {
                click() {
                  this.form.data.planGoods.push(formGoodsInit())
                }
              }
            },
            selection: {
              data: [] as EnocloudServiceDefinitions['ServiceQuotationGoodsDto'][]
            },
            delete: {
              click(index: number) {
                this.form.data.planGoods.splice(index, 1)
              }
            },
            toService: {
              async click(row: EnocloudServiceDefinitions['ServiceQuotationGoodsDto']) {
                const data = cloneDeep(this.service.data)
                data.goods?.push({
                  goods: row.goods,
                  goodsSpecification: row.goodsSpecification,
                  amount: 0,
                  amountBeforeDiscount: 0,
                  count: 0,
                  discountRate: row.discountRate,
                  fakeCount: 0,
                  histories: [],
                  materialCount: 0,
                  materialFakeCount: 0,
                  planCount: row.count,
                  price: row.price,
                  returnedCount: 0,
                  comment: row.comment,
                  chargingMethod: row.chargingMethod
                })
                await this.service.submit({ addition: data })
                this.service.get()
                return EnMessage.success('添加成功')
              }
            },
            goods: {
              change(row: EnocloudServiceDefinitions['ServiceQuotationGoodsDto'], goods: EnocloudServiceDefinitions['GoodsDto']) {
                const { specifications } = goods

                const goodsSpecification = find(specifications, ['defaultService.value', true]) || specifications?.[0]
                goodsSpecification!.goods = cloneDeep(goods)

                row.goods = goods
                row.goodsSpecification = goodsSpecification
                row.price = goodsSpecification!.servicePrice
              }
            },
            count: {
              change(row: EnocloudServiceDefinitions['ServiceQuotationGoodsDto']) {}
            },
            discountRate: {
              change(row: EnocloudServiceDefinitions['ServiceQuotationGoodsDto']) {}
            },
            price: {
              change(row: EnocloudServiceDefinitions['ServiceQuotationGoodsDto']) {}
            },
            goodsComment: {
              change(row: EnocloudServiceDefinitions['ServiceQuotationGoodsDto']) {}
            },
            print: {
              change(row: EnocloudServiceDefinitions['ServiceQuotationGoodsDto'], value: boolean) {
                row.print = { code: value ? 'Y' : 'N', message: '', type: '', description: '', value }
              }
            }
          },
          maintenanceHistory: {
            ajax: {
              get: {
                action: 'GET /enocloud/service/quotation/maintenance',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = { vehicleId: this.form.data.vehicle?.id }
                }
              }
            }
          },
          planGoodsHistory: {
            ajax: {
              get: {
                action: 'GET /enocloud/service/quotation/goods',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = { vehicleId: this.form.data.vehicle?.id }
                }
              }
            }
          }
        }
      },
      service: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/:serviceId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.vehicleServiceGroup?.serviceId]
            }
          },
          submit: {
            action: 'PUT /enocloud/service',
            loading: true
          }
        }
      },
      maintenanceSelection: {
        visible: false,
        confirm(data: MaintenanceSelectionOption<EnocloudCommonDefinitions['MaintenanceDto']>[]) {
          for (const item of data) {
            const exist = find(this.form.data.maintenances, ['maintenance.id', item.maintenance.id])
            if (exist) {
              exist.laborHour = item.laborHour
              exist.price = item.price
            } else {
              this.form.data.maintenances.push(formMaintenanceInit({ maintenance: item.maintenance, laborHour: item.laborHour, price: item.price }))
            }
          }
        }
      },
      goodsSelection: {
        visible: false,
        confirm(data: GoodsSelectionOption<EnocloudServiceDefinitions['GoodsDto'], EnocloudServiceDefinitions['GoodsSpecificationDto']>[]) {
          for (const item of data) {
            const exist = find(this.form.data.planGoods, ['goodsSpecification.goods.id', item.goods.id])
            if (exist) {
              exist.count = calculator.add(exist.count, item.count)
            } else {
              this.form.data.planGoods.push(
                formGoodsInit({
                  goods: item.goods,
                  goodsSpecification: Object.assign(item.goodsSpecification!, { goods: item.goods }),
                  count: item.count,
                  price: item.servicePrice
                })
              )
            }
          }
        }
      },
      dialog: {
        visible: false
      },
      detail: {
        visible: false,
        confirm(id: number | undefined) {
          this.form.data.id ??= id
          this.form.get()
        }
      },
      vehicleRecord: {
        visible: false
      },
      customerRecord: {
        visible: false
      },
      logs: {
        visible: false
      },
      spraySurface: {
        visible: false,
        data: {
          codes: [] as string[],
          maintenances: [] as EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto'][]
        },
        children: {
          codes: {
            async change() {
              const { maintenances, codes } = this.spraySurface.data
              const formMaintenacesCodes: string[] = maintenances.map((item) => item.maintenance!.spraySurface!) ?? []

              const append_codes = difference(codes, formMaintenacesCodes)
              const delete_codes = difference(formMaintenacesCodes, codes)

              for (const code of delete_codes) {
                const index = findIndex(maintenances, ['maintenance.spraySurface', code])
                if (index > -1) maintenances.splice(index, 1)
              }

              for (const code of append_codes) {
                const res = await this.ajax('GET /enocloud/common/maintenance', { payload: { spraySurface: code } })
                if (res.data[0]) {
                  const init = formMaintenanceInit({
                    maintenance: res.data[0],
                    name: res.data[0].name,
                    laborHour: 1,
                    price: res.data[0].unitPrice
                  })
                  maintenances.push(init)
                }
              }
            }
          },
          maintenances: {
            delete: {
              click(index: number) {
                this.spraySurface.data.maintenances.splice(index, 1)
                this.spraySurface.data.codes = this.spraySurface.data.maintenances.map((item) => item.maintenance?.spraySurface!)
              }
            },
            add: {
              click() {
                this.spraySurface.data.maintenances.push(formMaintenanceInit({ laborHour: 1 }))
              }
            },
            change(row: EnocloudServiceDefinitions['ServiceQuotationMaintenanceDto']) {
              row.price = row.maintenance?.unitPrice ?? 0
            }
          },
          footer: {
            cancel: {
              click() {
                this.spraySurface.visible = false
              }
            },
            confirm: {
              async click() {
                const { maintenances = [] } = this.form.data
                const formMaintenaces = maintenances?.filter((item) => !isNil(item.maintenance?.spraySurface))
                const formMaintenacesCodes: string[] = map(formMaintenaces, 'maintenance.spraySurface')

                const surfaceMaintenances = this.spraySurface.data.maintenances.filter((item) => !isNil(item.maintenance?.spraySurface))
                const surfaceMaintenancesCodes = map(surfaceMaintenances, 'maintenance.spraySurface')

                const append_codes = difference(surfaceMaintenancesCodes, formMaintenacesCodes)
                const delete_codes = difference(formMaintenacesCodes, surfaceMaintenancesCodes)

                for (const code of delete_codes) {
                  const index = findIndex(maintenances, ['maintenance.spraySurface', code])
                  if (index > -1) maintenances.splice(index, 1)
                }

                for (const code of append_codes) {
                  const item = find(surfaceMaintenances, ['maintenance.spraySurface', code])
                  if (item) this.form.data.maintenances.push(item)
                }

                this.spraySurface.footer.cancel.click()
              }
            }
          }
        }
      }
    }
  },

  watch: {
    'form.data': {
      handler() {
        if (this.form.data.vehicle?.id) {
          this.form.maintenanceHistory.get()
          this.form.planGoodsHistory.get()
        }

        if (this.form.data.vehicleServiceGroup?.serviceId) {
          this.service.get()
        } else {
          this.service.init()
        }
      }
    }
  }
})
</script>
